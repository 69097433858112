
import { useState, useEffect } from 'react';
import { useAppState } from './StoreContext';


export const useAuth = auth => {
  const [authenticated, setAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [state, dispatch] = useAppState();
  
  useEffect(() => {
    
    auth.isAuthenticated().then(isAuthenticated => {
      if (isAuthenticated !== authenticated) {
        setAuthenticated(isAuthenticated);
      }
    });
  });

  useEffect(() => {
    if (authenticated) {
      auth.getUser().then(puser => {
        dispatch({type: 'authFn', item: auth});
        dispatch({type: 'auth', item: puser, authFn: auth});

        return puser;
      }).then(setUser);
    } else {
      dispatch({type: 'setLogout'});
      setUser(null);
    }
  }, [authenticated]);

  return [authenticated, user];
};