import React, { Component } from 'react';

export default class Clients extends Component {
    render(){
        return (<section className="client_logo padding_top best_seller">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="section_tittle text-center">
                        <h2>Representates  <span>de</span></h2>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-human.svg" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-imtecbw.png" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-labtest.png" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-unico.png" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-ps.jpg" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-himedia.jpg" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-genrui.jpg" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-meril.png" alt="" />
                    </div>
                    <div className="single_client_logo">
                        <img src="/assets/img/logo-cortez.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>)
    }
}