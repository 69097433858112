import React, { Fragment } from "react";
import './404.css';

export default function NotFound(){
    return (<Fragment>
        <div className="not-container">
            <div>
                <h1 className="not-h1">404</h1>
                <div className="not-div">
                    <h2 className="not-h2">Esta página no pudo ser encontrada</h2></div></div></div>
    </Fragment>)
}