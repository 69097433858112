import React, { Component } from 'react';
import Pagination from '../Pagination';
import ProductCard from './ProductCard';
import CONSTANTS from '../../constants';
import { useAppState } from '../../StoreContext';


export default class ListProductView extends Component {
    constructor(props){
        super(props);
        this.state = {
            allProducts: [],
            currentProducts: [],
            currentPage: null,
            totalPages: null,
            busqueda: null
        };
        this.onEnter.bind(this);
    }
    getUser(){
        const arde_user = localStorage.arde_user;
        try {arde_user = JSON.parse(arde_user)} catch {};
        const rsp = arde_user.sub || '';
        return rsp;
    }
    
    componentDidMount(){
        const _this = this;
        const {user} = this.props.appState
        fetch(`${CONSTANTS.API_ENDPOINT}/p/all?sub=${user ? user.sub : ''}`,{
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin":"*"
            },
            credentials: "same-origin"
        })
            .then(res => res.json())
            .then(
                (products) => {
                    _this.setState({
                        allProducts: products
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                // <AddPerson people={this.state.people} addPerson={this.addPerson.bind(this)} />
                (error) => {
                    console.log(error);
                this.setState({
                    isLoaded: true,
                    error
                });
                }
            )
    }
    
    onPageChanged = data => {
        const { allProducts } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentProducts = allProducts.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentProducts, totalPages });
    };
    onEnter(ev){
        if (ev.key === 'Enter'){
            let _this = this;
            let busqueda = ev.target.value;
            ev.preventDefault();
            
            this.setState({allProducts: []})
            fetch(`${CONSTANTS.API_ENDPOINT}/p/search?id=${ev.target.value ? ev.target.value : ''}`)
                .then(res => res.json())
                .then(
                    (products) => {
                        if (products.msg) {
                            return _this.setState({
                                allProducts: [],
                                busqueda: busqueda
                            })
                        }
                        _this.setState({
                            allProducts: products,
                            busqueda: busqueda
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    // <AddPerson people={this.state.people} addPerson={this.addPerson.bind(this)} />
                    (error) => {
                        console.log(error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    }
                )
        }
    }
    render (){
        const {
            allProducts, 
            currentPage,
            currentProducts,
            totalPages
        } = this.state; 
        const totalProducts = allProducts.length;
        const notFound = totalProducts == 0 && this.state.busqueda;
        if (totalProducts == 0 && !notFound) return (<h3>Cargando...</h3>)
        
        return (<div class="col-lg-9">
        <div class="row">
            
            <div class="col-lg-12">
                
                <div class="product_top_bar">
                    
                    
                    <div class="single_product_menu d-flex">
                        <div class="input-group" style={{width: '350px'}}>
                            <input type="text" class="form-control" placeholder="buscar"
                                aria-describedby="inputGroupPrepend" onKeyDown={ev => this.onEnter(ev)}/>
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend"><i
                                        class="ti-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <Pagination totalRecords={totalProducts} 
                            pageLimit={18}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </div>
                    
                </div>
                <section className="cart_area">
                    <div className="container">
                        {notFound && <h3>Resultados no encontrados</h3>}
                        {this.state.busqueda && <h3>{totalProducts} resultados para {this.state.busqueda}...</h3> }
                        <div className="cart_inner">
                            <div class="row align-items-center latest_product_inner">
                                {currentProducts.map((product, indx) => (
                                    <ProductCard appState={this.props.appState} key={indx * Math.random() * 100} product={product} />
                                ))} 
                            </div>
                           
                        </div>
                    </div>
                </section>
                    
            </div>
        </div>
        </div>
        );
    }   
}