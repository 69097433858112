import React, { Component, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';

import withAuth from "@okta/okta-react/dist/withAuth";
import { useAppState } from "../../StoreContext";
import { useAuth } from "../../auth";
import Hero from '../Header/CartHero';

import './cart.css';

export default withAuth(({ auth }) => {
  let [state, dispatch] = useAppState();
  const [authenticated, user] = useAuth(auth);

  if (true) {
    let dispatchDeep = function (call) {
      dispatch(call);
    };
    dispatchDeep = dispatchDeep.bind(this);
    return <FullCart items={state} dispatch={dispatchDeep} />;
  } else {
    return (
      <div className="cart_area padding_top">
        {" "}
        <div className="container">
          {authenticated !== null && (
            <button
              onClick={() => (authenticated ? auth.logout() : auth.login())}
              className="App-link">
              Log {authenticated ? "out" : "in"}
            </button>
          )}
        </div>
      </div>
    );
  }
});

function FullCart({ items, dispatch }) {
  let cart = items.cart;
  let tax = 0.15;
  let [totaltax, setTotaltax] = useState(0);
  let [subtotal, setSubtotal] = useState(0);
  let [state] = useAppState();
  // Actualizar el subtotal, cuando cambie el carrito;
  useEffect(() => {
    setSubtotal(
      cart.reduce((prd, nxpr) => {
        return prd + ((getPrice(state, nxpr.product)) * nxpr.qty);
      }, 0)
    );
    setTotaltax(subtotal * tax);
  }, [cart, subtotal]);

  return (<Fragment>
    <Hero user={'arde_shop'} />
    <section className="cart_area">
      <div className="container">
        <div className="cart_inner">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col ">Ref.</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Precio</th>
                  <th scope="col" className="col-center">QTY</th>
                  <th scope="col" className="align-left">Total</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item, et) => {
                  return (
                    <ProductRow
                      key={item.ref}
                      product={item}
                      dispatch={dispatch}
                    />
                  );
                })}
                
                <tr className="row-left">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5>Subtotal</h5>
                  </td>
                  <td>
                    <h5 className="subtotal">
                        <CurrencyFormat value={ state.authenticated ? subtotal : 0} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
                  </h5>
                  </td>
                </tr>
                <tr className="row-left">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5>ISV (15%)</h5>
                  </td>
                  <td>
                    <h5 className="subtotal">
                      <CurrencyFormat value={state.authenticated ? totaltax : 0 } decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
                     </h5>
                  </td>
                </tr>
                <tr className="row-left">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <h5>Total</h5>
                  </td>
                  <td>
                    <h5 className="grantotal">
                      <CurrencyFormat value={state.authenticated ? subtotal + totaltax : 0} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
                  </h5>
                  </td>
                </tr>
                <tr className="shipping_area">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="shipping desktop">
                    <h5>Envio</h5>
                  </td>
                  <td className="desktop">

                    <div className="shipping_box">
                      <ul className="">
                        <li>
                          <a href="#">Local L. 0.00 </a>

                        </li>
                        <li>
                          <a href="">Interior HN: L 100.00</a>
                        </li>
                      </ul>
                      <br />
                      
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="checkout_btn_inner float-right">
              <a className="btn_1" href="#">
                Continuar comprando
              </a>
              <a className="btn_1 checkout_btn_1" href="#">
                Finalizar compra
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </Fragment>
  );
}

class ProductRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      p: this.props.product,
    };
  }
  /**
   * Modificar la cantidad del producto
   * @ev es el evento que modifica
   * @action es el tipo de acción, si incrementa o decrementa
   * Manda un dispatch al AppState para actualizar el carrito global.
   */
  addQty(ev, action) {
    ev.preventDefault();
    let p = this.state.p;
    if (action == 1) {
      if (p.qty > 0) --p.qty;
    } else {
      if (p.qty < 100) ++p.qty;
    }

    this.props.dispatch({
      type: "updateCart",
      item: this.state.p,
    });
    this.setState({
      ...this.state,
      p: p,
    });
  }
  handleChange(ev) {
    let p = this.state.p;
    ev.preventDefault();
    p.qty = ev.target.value;

    this.props.dispatch({
      type: "updateCart",
      item: this.state.p,
    });
    this.setState({
      ...this.state,
      p: p,
    });
  }

  render() {
    return (
      <tr id={`row-${this.state.p.product.sku}`}>
        <td>
          <div className="media">
            
            <div className="media-body">
              <p>
                <Link to={`/p/${this.state.p.product.id}`}>
                  {this.state.p.product.sku}
                </Link>
              </p>
            </div>
          </div>
        </td>
      <td className={"product-description"}>{this.state.p.product.nombre}</td>
        <td>
          <Precio product={this.state.p.product} />
        </td> 
        <td className="col-center">
          <div className="product_count ">
            <span className="input-number-decrement">
              {" "}
              <i
                className="ti-angle-down"
                onClick={(ev) => {
                  this.addQty(ev, 1);
                }}></i>
            </span>
            <input
              className="input-number"
              type="text"
              value={this.state.p.qty}
              onChange={(ev) => this.handleChange(ev)}
              min="0"
              max="10"
            />
            <span className="input-number-increment">
              {" "}
              <i
                className="ti-angle-up"
                onClick={(ev) => {
                  this.addQty(ev, 2);
                }}></i>
            </span>
          </div>
        </td>
        <td className="align-left">
          <RowSubtotal item={this.state.p}/>
        </td>
      </tr>
    );
  }
}

function getPrice(state, product) {
  let {user} = state;
  console.log(user.consumo , Boolean(user.consumo))
  if (user && user.nickname && product.precio[user.nickname]) return product.precio[user.nickname]
  else if (user && user.consumo) return product.precio.consumo || product.precio.general;
  else return product.precio.general; 
}

function Precio({product}){
  let [state] = useAppState();
  console.log(state)
  let {precio} = product;
  if (state.authenticated) {
   precio = getPrice(state, product);
   return (<h5 className="price-number">
      <CurrencyFormat value={precio || 0} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
   </h5>)
  } else {
     return (<p>Accede para ver precio</p>)
  }
}

function RowSubtotal({item}){
  let [state] = useAppState();
  let {precio} = item.product;
  if (state.authenticated) {
      precio = getPrice(state, item.product);
      return (<h5 className="price-number">
            <CurrencyFormat value={item.qty * precio} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
          </h5>)
  } else {
    return (<h5 className="price-number">0.00</h5>)
  }
}