import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import CurrencyFormat from 'react-currency-format'; 
import { useAppState } from '../../StoreContext';
export default class ProductCard extends Component {
   constructor(props){
      super(props);
      this.addToCart = this.addToCart.bind(this);
   }
    state = {
        qty: 0
    }
    addToCart(ev){
    }
    render() {
      const {qty} = this.state;
      const { sku: ref2 = "", id = null, marca = 'Human', nombre = null, precio = {},  detalle = null } =
        this.props.product || {};
  
      return (
        <Fragment>
            <div className="col-lg-12 col-sm-12">
               <div className="single_product_item">

                  <div className="single_product_text id">
                     <h4><Link to={`/p/${id}`} alt="Ver producto">{nombre}</Link></h4>
                     <ul className="product_details">
                        <li><strong>Ref.</strong><Link to={`/p/${id}`} alt="Ver producto">{ref2}</Link></li>
                        <li><strong>Marca:</strong> {marca}</li>
                        <li><strong>Presentación:</strong> {detalle}</li>
                     </ul>
                    
                     <Precio product={this.props.product}/>
                     <AddToCart product={this.props.product} />
                  </div>
               </div>
            </div>
         
        </Fragment>
      );
    }
  }


  function getPrice(state, product) {
   let {user} = state;
   let {precio} = product;
   if (!precio) return 0;
   if (user && user.nickname && precio[user.nickname]) return precio[user.nickname];
   else if (user && user.consumo) return precio.consumo || precio.general;
   else return precio.general; 
 }
 
 function Precio({product}){
   let [state] = useAppState();
   let {precio} = product;
   if (state.authenticated) {
    precio = getPrice(state, product);
    return (<h5 className="price-number">
       <CurrencyFormat value={precio || 0} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
    </h5>)
   } else {
      return (<p>Accede para ver precio</p>)
   }
 }
  function AddToCart({product}){
     let [state, dispatch] = useAppState();
     
     return <a href="#" className="add_cart" onClick={ev => {
      dispatch({
         type:'addToCart',
         item: {
             ref: product.id,
             qty: Number(1),
             product: product
         }
     });
     }}>+ Agregar<i className="ti-heart"></i></a>
  }
  /**
   * <th scope="col ">Ref.</th>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Presentación</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col" className="col-center">QTY</th>
                                            <th scope="col" className="align-left">Total</th>
                                            <tr>
             <td>
                <h5></h5>
             </td>
             <td>
                <h5></h5>
             </td>
             <td>
                <h5>{detalle}</h5>
             </td>
             <td>
                <h5>{precio.general}</h5>
             </td>
             <td>
                <h5>{qty}</h5>
             </td>
             <td>
                <h5>{qty}</h5>
             </td>

         </tr>
   */