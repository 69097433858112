import React, {
  createContext,
  useContext,
  useReducer
} from "react";
import useLocal from "./useLocal";


export const AppContext = createContext();

export function useAppState() {
  return useContext(AppContext);
}

/**
 * CARRO: {11011: 4, 17501: 1, 31411:1}
 * [{ref: 11101, qty: 4}]
 */
const appStateReducer = (state, action) => {
  switch (action.type) {
    case 'authFn': {
      return {
        ...state,
        authFn: action.item
      };
    }
    case 'setLogout': {
      return {
        ...state,
        authenticated: false,
        user: {}
      }
      break;
    }
    case 'logout': {

      if (typeof state.authFn == 'object') {
        state.authFn.logout();
      }
      return {
        ...state,
        authenticated: false
      }
    }
    case 'login': {

      if (typeof state.authFn == 'object') {
        state.authFn.login();
      }
      return {
        ...state,
        authenticated: true
      }
    }
    case 'auth': {
      if (!action.item) return;
      let auth = action.item;
      auth.isAuth = true;
      localStorage.setItem("arde_user", JSON.stringify(auth))
      return {
        ...state,
        user: auth,
        authenticated: true,
        authFn: action.authFn
      }
    }
    case "addToCart": {
      // Agrega 
      let newCart = state.cart;
      let index;
      let hasProduct = state.cart.some((val, ind) => {
        return val.ref == action.item.ref;
      });
      if (hasProduct) {
        newCart = state.cart.map((item) => {
          if (action.item.ref == item.ref) {
            let oldVal = Number(item.qty);
            item.qty = Number(action.item.qty) + oldVal;
            item.product = action.item.product;
            return item;
          } else {
            return item;
          }
        });
      } else {
        newCart.push(action.item);
      }
      newCart = cleanup(newCart);
      localStorage.setItem("arde_cart", JSON.stringify(newCart));

      return {
        ...state,
        cart: newCart,
      };
    }
    case "updateCart":
      let toUpdate = action.item;
      let isFound = false;
      let newCart = state.cart.map(item => {
        if (item.ref == toUpdate.ref) {
          isFound = true;
          return toUpdate;
        } else return item;
      });
      if (!isFound) newCart.push(toUpdate);
      newCart = cleanup(newCart);
      localStorage.setItem("arde_cart", JSON.stringify(newCart));
      return {
        ...state,
        cart: newCart
      }
      case "readLocal":
        let cart = cleanup(action.cart);
        return {
          ...state,
          cart: cart
        }

        default:
          return state;
  }
};

function cleanup(arr) {
  let narr = [];
  arr.forEach(el => {
    if (el.qty !== 0) narr.push(el);
  })
  return narr;
}
const initialState = {
  cart: [],
  auth: {},
  authenticated: false,
  authFn: null
};

export function StoreStateProvider({
  children
}) {
  let cartHandler = useReducer(appStateReducer, initialState);


  return <AppContext.Provider value = {
    cartHandler
  } > {
    children
  } < /AppContext.Provider>;
}