import React from 'react';

class ProductList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        people: []
      }
    }
  
    componentDidMount() {
      fetch("/api.json",{
      method: "GET",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin":"*"
      },
      credentials: "same-origin"
   })
        .then(res => res.json())
        .then(
          (result) => {
              console.log(result);
            this.setState({
              isLoaded: true,
              people: result.phones
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          // <AddPerson people={this.state.people} addPerson={this.addPerson.bind(this)} />
          (error) => {
              console.log(error);
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    addPerson(person) {
      let people = this.state.people;
  
      people.push(person);
  
      this.setState({
        people: people
      })
    }
  
    render() {
      return (
        <div>
          <h1>Directorio Telefónico</h1>
          <PeopleList people={this.state.people} />
          
        </div>
      )
    }
  }
  
  class PeopleList extends React.Component {
    constructor() {
      super();
      this.state = {
        search: '',
        lugar: 'Todos',
        categoria: 'Todos'
      }
    }
      updateLugar(event){
          this.setState({
              lugar: event.target.value
          })
      }
      updateSearch(event) {
          this.setState({
              search: event.target.value
          })
      }
      updateCategoria(event){
          this.setState({
              categoria: event.target.value
          })
      }
  
    render() {
      let filteredPeople = this.props.people.filter((person) => {
          let fullName = person.nombre.toLowerCase();
          if (this.state.lugar == 'Todos' && this.state.categoria == 'Todos') {
              return fullName.indexOf(this.state.search.toLowerCase()) !== -1;
          }
  
          if (this.state.lugar !== 'Todos' && this.state.categoria == 'Todos'){    
             return person.lugar == this.state.lugar && fullName.indexOf(
            this.state.search.toLowerCase()) !== -1;
          } else if (this.state.lugar !== 'Todos' && this.state.categoria !== 'Todos'){
              return person.lugar == this.state.lugar && person.categoria == this.state.categoria && fullName.indexOf(
            this.state.search.toLowerCase()) !== -1;
          }
  
          if (this.state.categoria !== 'Todos' && this.state.lugar == 'Todos'){
              return person.categoria == this.state.categoria && fullName.indexOf(
            this.state.search.toLowerCase()) !== -1;
          }
  
        }
      );
  
      return (
        <div className="people-list">
               <div className="search">
                  <input type="text" 
                      className="search_input"
                      value={this.state.search}
                      onChange={this.updateSearch.bind(this)}
                      placeholder="Ingrese el nombre del negocio"
                />
              <select id="lugares" class="field-filter" name="lugares" value={this.state.lugar} 
                  onChange={this.updateLugar.bind(this)} >
                  <option value="Nacional" selected>Nacional</option>
                  <option value="TGU">Tegucigalpa</option>
                  <option value="SPS">San Pedro Sula</option>
                  <option value="CBA">La Ceiba</option>
                  <option value="Comayagua">Comayagua</option>
                  <option value="Choluteca">Choluteca</option>
              </select>
              <select id="categoria" class="field-filter" name="categoria" value={this.state.categoria} 
                  onChange={this.updateCategoria.bind(this)} >
                  <option value="Todos" selected>Todos</option>
                  <option value="Farmacias">Farmacias</option>
                  <option value="Restaurantes">Restaurantes</option>
                  <option value="Supermercados">Supermercados</option>
                  <option value="Insumos">Insumos</option>
                  <option value="Mascotas">Mascotas</option>
                  <option value="Emergencia">Emergencia</option>
              </select>
               </div>
              <table className="list">
                  <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Lugar</th>
                      <th>Telefono</th>
                      <th>Horario</th>
                      <th>Categoria</th>
                  </tr>
                  {filteredPeople.map((person, indx)=> {
                      indx = indx + 1;
                      return <Person person={person}  indx={indx}/>
                  })}
              </table>
        </div>
  
      )
    }
  }
  
  class Person extends React.Component {
      handleClick (e){
          console.log(e.website);
      }
      render() {
          return (
              <tr onClick={()=>{this.handleClick(this.props.person)}}>
                  <td>{this.props.indx}</td>
                  <td>{this.props.person.nombre}</td>
                  <td>{this.props.person.lugar}</td>
                  <td>{this.props.person.telefono}</td>
                  <td>{this.props.person.horario}</td>
                  <td>{this.props.person.categoria}</td>
              </tr>
          )
      }
  }
  
  class AddPerson extends React.Component {
  
    componentWillMount() {
      this.setState({
        nombre: "",
        lugar: "",
        telefono: "",
        horario: ""
      });
    }
  
    handleSubmit(e) {
      e.preventDefault();
      this.props.addPerson(this.state)
    }
  
    handleChange(data) {
      let state = this.state;
      let name = data.target.name;
      state[name] = data.target.value;
      this.setState(state);
    }
  
    render() {
  
      return (
           <div className="person-add">
              <form className="add-form" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-field">
                      <label>Name: </label>
                      <input type="text"
                       name="firstName"
                       value={this.props.firstname}
                       onChange={this.handleChange.bind(this)}
                      />
                  </div>
                  <div className="form-field">
                      <label>Lastname: </label>
                      <input type="text"
                       name="lastName"
                       value={this.props.lastname}
                       onChange={this.handleChange.bind(this)}
                      />
                  </div>
                  <div className="form-field">
                      <label>Phone: </label>
                      <input type="text"
                       name="phone"
                       value={this.props.phone}
                       onChange={this.handleChange.bind(this)}
                      />
                  </div>
                <button type="submit">Add</button>
              </form>
          </div>
      )
    }
  }
  
  export default ProductList;