import React, { Component } from 'react';

export default class Hero extends Component {
    componentDidMount(){
        window.PLUGINS(window.jQuery);
    }
    render(){
        return (<section className="banner_part">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="banner_slider owl-carousel">
                        <div className="single_banner_slider">
                            <div className="row">
                                <div className="col-lg-5 col-md-8">
                                    <div className="banner_text">
                                        <div className="banner_text_iner">
                                            <h1>Prueba Rápida COVID-19 IgG-IgM</h1>
                                            <p>Preordena la prueba rápida de Human GmbH</p>
                                            <a href="mailto:s.cliente@a-arde.com?subject=Acerca de las pruebas de COVID-19&body=Estoy interesado en las pruebas de SARS-CoV-2" className="btn_2">Preordenar ahora</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner_img m-show d-lg-block">
                                    <img src="/assets/img/banner-covid.png" alt="" />
                                </div>
                            </div>
                        </div><div className="single_banner_slider">
                            <div className="row">
                                <div className="col-lg-5 col-md-8">
                                    <div className="banner_text">
                                        <div className="banner_text_iner">
                                            <h1>HumaLyzer 4000 </h1>
                                            <p>Un pionero en el campo de los fotómetros para Química Clínica</p>
                                            <a href="https://www.human.de/es/productos/quimica-clinica/humalyzer-fotometros/humalyzer-4000/" className="btn_2">Leer más</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner_img m-show d-lg-block">
                                    <img src="/assets/img/banner-hl4000.png" alt="" style={{width:'500px'}} />
                                </div>
                            </div>
                        </div><div className="single_banner_slider">
                            <div className="row">
                                <div className="col-lg-5 col-md-8">
                                    <div className="banner_text">
                                        <div className="banner_text_iner">
                                            <h1>HumaCount 5D</h1>
                                            <p>Sistema automatizado de diferencial de 5 partes</p>
                                            <a href="#" className="btn_2">Leer más.</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner_img m-show d-lg-block img-fix-5d">
                                    <img src="/assets/img/banner-hc5d.png" alt="Banner" />
                                </div>
                            </div>
                        </div><div className="single_banner_slider">
                            <div className="row">
                                <div className="col-lg-5 col-md-8">
                                    <div className="banner_text">
                                        <div className="banner_text_iner">
                                            <h1>Reactivos para Hemostasia</h1>
                                            <p>Tromboplastina, TPT, Fibrinogeno, Dimero-D, Anti-Trombina</p>
                                            <a href="#" className="btn_2">Leer más.</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner_img m-show d-lg-block">
                                    <img src="/assets/img/banner-hemostasis.png" alt="" style={{width: '500px'}} />
                                </div>
                            </div>
                        </div>

                    
                    </div>
                    <div className="slider-counter"></div>
                </div>
            </div>
        </div>
    </section>)
    }
}