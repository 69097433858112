import React, {
    Component
} from 'react';
import logo from '../../logoarde.svg';
import {Link} from 'react-router-dom';
import { useAppState } from '../../StoreContext';
import useLocal from '../../useLocal';
import withAuth from '@okta/okta-react/dist/withAuth';
import { useAuth } from '../../auth';
import './header.css';

export default withAuth(({auth}) => {       
    useLocal();
    let [state, dispatch] = useAppState();
    const [authenticated, user] = useAuth(auth);
    
    // load all plugins on render
    window.PLUGINS(window.jQuery);

    return [
        <header className="main_menu home_menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="/"> 
                                <img src={logo} className="App-logo" alt="logo" />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="menu_icon"><i className="fas fa-bars"></i></span>
                                </button>

                                <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link">Inicio</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="/tienda" id="navbarDropdown_1"
                                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Tienda
                                            </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown_1">
                                                <Link to="/tienda" className="dropdown-item">Categorias</Link>
                                                <Link to="/p/1" className="dropdown-item">Productos</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown_3"
                                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Páginas
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown_2">
                                                <Link to="/login" className="dropdown-item">Login</Link>
                                                <Link to="/track" className="dropdown-item">Rastrear pedido</Link>
                                                <Link to="/cart" className="dropdown-item">Carrito</Link>
                                                <Link to="/checkout" className="dropdown-item">Finalizar compra</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown_2"
                                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                blog
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown_2">
                                                <Link to="//blog.a-arde.com" className="dropdown-item">Blog</Link>
                                            </div>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <a className="nav-link" href="contact.html">Contacto</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="hearer_icon d-flex">
                                            {authenticated && user ? <Link to="/account" className="app-username">{user.name}</Link> : ''}
                                            &nbsp;<a
                                              onClick={() => authenticated ? auth.logout(): auth.login()}
                                              className="App-link"
                                              title={authenticated ? 'Cerrar Sesión' : 'Iniciar Sesión'}
                                            >
                                               <i className={authenticated ? 'ti-new-window' : 'ti-shift-left'}></i>
                                            </a>
                                   
                                    <Link to="/favoritos"  className="App-link"><i className="ti-heart"></i></Link>
                                    <div className="dropdown cart">
                                        <Link to="/cart" title="Ir al Carrito" className="App-link">
                                            <CartPopup state={state}/>
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="search_input" id="search_input_box">
                    <div className="container ">
                        <form className="d-flex justify-content-between search-inner">
                            <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                            <button type="submit" className="btn"></button>
                            <span className="ti-close" id="close_search" title="Close Search"></span>
                        </form>
                    </div>
                </div>
            </header>
    ]
})

function CartPopup({state}){
    let length = state.cart.length;
    if (length > 20) {
       return  <i className="fas fa-cart-plus active cc"></i>
    }   else if (length > 0 && length < 20) {
        return  <i className={`fas fa-cart-plus active c${length}`}></i>
    }  else {
        return  <i className="fas fa-cart-plus"></i>
    }
}