
import React from 'react';
import './cart.css';
import withAuth from '@okta/okta-react/dist/withAuth';
import { useAuth } from '../../auth';


export default withAuth(({auth})=>{
    const [authenticated, user] = useAuth(auth);
    let client = user && authenticated ? user.nickname : 'arde_shop';

    return (<section className="breadcrumb breadcrumb_bg">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="breadcrumb_iner">
            <div className="breadcrumb_iner_item">
              <h2>Tu Carrito</h2>
                <p>{client}&nbsp;- {user ? user.name : ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>)
});
