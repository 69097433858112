import React from 'react';
import { withAuth } from '@okta/okta-react';
import { useAuth } from '../../auth';
import { useAppState } from '../../StoreContext';
import ProductList from '../Product';
import Categories from './categories';
import './shop.css';

let people = []
const Store = withAuth(({ auth }) => {
  const [state, dispatch] = useAppState();
  const [authenticated, user] = useAuth(auth);
  return [
    <section className="breadcrumb breadcrumb_bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="breadcrumb_iner">
                        <div className="breadcrumb_iner_item">
                            <h2>Tienda</h2>
                            <p>Inicio <span>-</span> Comprar por categoria</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>,

    <div className="store">
        <Categories appState={state} />
       
        
        {authenticated !== null && (
          <button
            onClick={() => authenticated ? auth.logout() : auth.login()}
            className="App-link"
          >
            Log {authenticated ? 'out' : 'in'}
          </button>
        )}
     
    </div>
  ];
});

export default Store;

