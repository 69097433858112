import React, { Component, Fragment } from "react";
import {Link, Redirect, withRouter} from 'react-router-dom';
import { useAppState } from "../../StoreContext";
import {withOktaAuth} from "@okta/okta-react";
import CurrencyFormat from "react-currency-format";
import CONSTANTS from "../../constants";


export default class ProductView extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            notFound: false,
            prod: {},
            qty: 0
        }
    }
    handleChange(val){
        console.log(val, this.state.qty);
    }

    componentWillMount(){
        let id = this.props.match.params.idpr;
        fetch(`${CONSTANTS.API_ENDPOINT}/p/${id}`,{
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin":"*"
            },
            credentials: "same-origin"
        })
        .then(res => res.json())
        .then(
          (result) => {
              if (result && result.msg == 'Not found'){
                  return this.setState({
                      isLoaded: false,
                      notFound: true
                  })
              } 
              this.setState({
                  isLoaded: true,
                  prod: result.product
              })
          }, error =>{
              this.setState({
                  isLoaded: false,
                  notFound: true,
                  error
              })
          })
    }
    render (){
        let handler = this.props.match.params.idpr;
        if (this.state.notFound) {
            return <Redirect to="/404" />
        } else return ([
        <div className="product_image_area section_padding">
        <div className="container">
        <div className="row s_product_inner justify-content-between">
            {this.state.isLoaded ?
                <RenderProduct action={this.props.action} product={this.state.prod} />
            : <p>Loading...</p> }
        </div>
        </div>
        </div>,<section className="product_description_area"><div className="container"></div></section>]
        )
    }
};

class RenderProduct extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            qty: 1
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(ev){
        ev.preventDefault();
        this.setState({qty: ev.target.value});
    }
    addQty(ev, action){
      ev.preventDefault();
      let qty = this.state.qty;
      if (action == 1) {
        if (qty > 0) --qty;
      } else {
        if (qty < 100) ++qty;
      }

     
      this.setState({
        ...this.state,
        qty: qty
      });
    }
render(){
 //<h5>Anterior <span>|</span> Siguiente</h5>
    
    return (<Fragment>
        <div className="col-lg-7 col-xl-7">
                <div className="product_slider_img">
                    <div id="vertical2">
                        <div data-thumb="/assets/img/product/single-product/product_1.png">
                            <img src={this.props.product.img} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-xl-4">
                <div className="s_product_text">
                    
                    <h3>{this.props.product.nombre}</h3>
                    <h2>Ref. {this.props.product.sku}</h2>
                    <ul className="list">
                        <li>
                        <Link to="/" className="active" >
                        <span>Categoria</span> : {this.props.product.cat}</Link>
                        </li>
                        <li>
                        <Link to="#"> <span>Disponibilidad</span> : &nbsp; En Stock</Link>
                        </li>
                        <ListPrice item={this.props.product} />
                    </ul>
                    <p>
                        Presentación: {this.props.product.detalle}<br/>
                        Marca: <strong>{this.props.product.marca}</strong>
                    </p>
                    <div className="card_area d-flex justify-content-between align-items-center">
                        <div className="product_count">


                            <span className="input-number-decrement" > <i className="ti-angle-down" onClick={ev =>{
                                this.addQty(ev, 1)
                                }}></i></span>
                            <input id="pqty"className="input-number" type="text" value={this.state.qty} onChange={
                                this.handleChange}  min="0" max="10" />
                            <span className="input-number-increment" > <i className="ti-angle-up" onClick={ev =>{
                                this.addQty(ev, 2)
                                }}></i></span>
                        
                        </div>

                        <AddToCart {...this.props} {...this.state}/>

                        <Link to="#" className="like_us"> <i className="ti-heart"></i> </Link>
                    </div>
                </div>
            </div></Fragment>
    );
}
}


function ListPrice({item}) {
    let [state] = useAppState();
    if (state.authenticated) {
                
        return <li>Precio: &nbsp;
            <CurrencyFormat value={ item.precio.general || 0} decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'L'} />
        </li>
    }
    return <li>Precio: (Login para ver detalle)</li>
}

function AddToCart ({product, qty}){
    let [state, dispatch] = useAppState(); 
    return (
        <Link to="#" className="btn_3"  onClick={e => dispatch({
            type:'addToCart',
            item: {
                ref: product.id,
                qty: Number(qty),
                product: product
            }
        })}>Agregar</Link>
    );
}