import React from 'react';
import '../App/App.css';
import Hero from '../Header/Hero';
import Clients from '../Footer/Clients';

let people = [];

const Home = () => { 
        return [
        <Hero />,
        <Clients />
        ]
    
}

export default Home;
