


import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import NotFound from './components/404';
import Home from './components/Home';
import ProductView from './components/Product/id';
import Header from './components/Header';
import Store from './components/Shop';
import Cart from './components/Cart';
import Footer from './components/Footer';
import Checkout from './components/Checkout';
import {StoreStateProvider} from './StoreContext';
import * as serviceWorker from './serviceWorker';


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, ImplicitCallback } from '@okta/okta-react';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      cart: [1,2,3,4],
      keys: [1]
    };
    this.cartLength = this.cartLength.bind(this);
    this.changeCart = this.changeCart.bind(this);
  }
  changeCart(prod){
    let state = this.state;
    console.log(this.state.cart)
    state.cart.push(prod.id);
    this.setState(state);
  }
  cartLength(){
    return this.state.cart.length;
  }
  render (){
    return (
      <StoreStateProvider>
        <Router>
          <Security
            issuer={`${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`}
            client_id={process.env.REACT_APP_OKTA_CLIENT_ID}
            redirect_uri={`${window.location.origin}/implicit/callback`}
          >
            <Header key={this.state.keys[0]}/>
            <Switch>
              <Route path="/" exact >
                  <Home />
              </Route>
              <Route path="/cart" exact component={Cart} />
              <Route path="/tienda" exact component={Store} />
              <Route path="/checkout" exact component={Checkout} />
              <Route path="/p/:idpr" render={routeProps =>
                (<ProductView action={this.changeCart} {...routeProps}/>)
                } />
              <Route path="/implicit/callback" component={ImplicitCallback} />
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </Security>
        </Router>
    </StoreStateProvider>)
  }
  
}
ReactDOM.render(<App />,  document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();