import React, {
    Component, Fragment
} from 'react';

export default class Footer extends Component {
    render() {
        return (<Fragment><footer className="footer_part">
        <div className="container">
            <div className="row justify-content-around">
                <div className="col-sm-6 col-lg-2">
                    <div className="single_footer_part">
                        <h4>Categorias</h4>
                        <ul className="list-unstyled">
                            <li><a href="">Quimica Clinica</a></li>
                            <li><a href="">Hematologia</a></li>
                            <li><a href="">Pruebas Rápidas</a></li>
                            <li><a href="">Equipos por Consumo</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-2">
                    <div className="single_footer_part">
                        <h4>Productos más vendidos</h4>
                        <ul className="list-unstyled">
                            <li><a href="">Reactivo para Hematologia</a></li>
                            <li><a href="">Glicohemoglobina A1c</a></li>
                            <li><a href="">Prueba Rápida de Influenza</a></li>
                            <li><a href="">Terminos de Servicio</a></li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-sm-6 col-lg-4">
                    <div className="single_footer_part">
                        <h4>Suscribete</h4>
                        <p>Obten información acerca de los últimos productos y disponibilidad.</p>
                        <div id="mc_embed_signup">
                            <form target="_blank"
                                action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                method="get" className="subscribe_form relative mail_part">
                                <input type="email" name="email" id="newsletter-form-email" placeholder="Correo Electronico"
                                    className="placeholder hide-on-focus"
                                    />
                                <button type="submit" name="submit" id="newsletter-submit"
                                    className="email_icon newsletter-submit button-contactForm">Suscribirse</button>
                                <div className="mt-10 info"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="copyright_part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="copyright_text">
                            <p>
                                Copyright &copy;{new Date().getFullYear()} Todos los derechos reservados. </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="footer_icon social_icon">
                            <ul className="list-unstyled">
                                <li><a href="#" className="single_social_icon"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/" className="single_social_icon"><i className="fas fa-globe"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer></Fragment>)
    }
}