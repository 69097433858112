import { useEffect } from "react";
import { useAppState } from "./StoreContext";

export default function useLocal() {
  let [state, dispatch] = useAppState();

  useEffect(() => {
    let toReadLocal = localStorage.getItem("arde_cart");
    dispatch({
      type: "readLocal",
      cart: toReadLocal ? JSON.parse(toReadLocal) : [],
    });
  }, [dispatch]);
}