import React, { Component, Fragment } from "react";
import {Link, useRouteMatch} from 'react-router-dom';
import ListProductView from './ListProductView';
import CONSTANTS from "../../constants";


export default class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            categories: {}
        }
    }
    addCategory(cat){
        let categories = this.state.categories;
        categories[cat] = cat;
        this.setState({
            categories: categories
        })
    }
    componentDidUpdate(){
        setTimeout(()=>{
            window.PLUGINS(window.jQuery);
        }, 500);
    }
    componentWillMount() {
        fetch(`${CONSTANTS.API_ENDPOINT}/cat/all`,{
            method: "GET",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*"
            },
            credentials: "same-origin"
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    categories: result
                });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                // <AddPerson people={this.state.people} addPerson={this.addPerson.bind(this)} />
                (error) => {
                    console.log(error);
                this.setState({
                    isLoaded: true,
                    error
                });
                }
            )
    }
    render(){
        let cats = this.state.categories;
        let id =0;
        return (<Fragment>
                <section class="cat_product_area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-3 desktop">
                                    <div class="left_sidebar_area">
                                        <aside class="left_widgets p_filter_widgets">
                                            <div class="l_w_title">
                                                <h3>Seleccionar:</h3>
                                            </div>
                                            <div class="widgets_inner">
                                                <ul class="list">
                                                    
                                                    
            {Object.keys(cats).map(function (a){
                ++id;
                let parser  = {id: id, cat: a, prod: cats[a]};
                return (<li><a href="">{a}</a><span>({cats[a].length})</span></li>)
            })}
                                                    
                                                </ul>
                                            </div>
                                        </aside>

                                        
                                    </div>
                                </div>
                                <ListProductView appState={this.props.appState} />
                                </div>
                            </div>
                        </section>
        </Fragment>)
    }
}

function Category(props, key) {
    let { path, url } = useRouteMatch();
    let cat = props.cat;
    return (<div className="single_product_item">
        <img src="/assets/img/product/product_1.png" alt="" />
        <div className="single_product_text">
            <h4><Link to={`${url}/${cat.id}`}>{cat.cat}</Link></h4>
            <h3>Total: {cat.prod.length}</h3>
        </div>
    </div>)
}


